
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Role extends Vue {
  private searchInfo = {
    logUserName: '',
    logCode: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '操作人姓名',
        prop: 'logUserName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '操作内容',
        prop: 'logCode',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '操作ip',
        prop: 'logIp',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '操作时间',
        prop: 'ctime',
        minWidth: '150',
        showOverflowTooltip: true
      }
    ],
    data: [{}]
  }

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.messageRecord.selectYhLogByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then((res) => {
      this.tableData.loading = false
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).catch(() => {
      this.tableData.loading = false
    })
  }
}
